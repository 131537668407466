.makeStyles-tableCell-137 {
  padding: 12px 8px;
  font-size: 0.8125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.42857143;
  vertical-align: initial !important;
  white-space: nowrap;
}

.MuiDialog-paperWidthSm {
  width: 100%;
}

.MuiDialog-paperScrollPaper {
  max-width: 1200px !important;
}
